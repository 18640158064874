import React from 'react'
import { Link } from 'gatsby'
import lifecycle from 'react-pure-lifecycle'
import $ from 'jquery'

import HomePageListItem from './homepagelistitem'
import HomeCatBar from './homecatbar'

import HomePageCSS from './homepage.module.css'

// create your lifecycle methods
const componentDidMount = props => {
  //Show corresponding image
  $('.logichome').mouseover(function() {
    $('.logichome').removeClass('washovered')
    $('.imagesolohome').css({ display: 'none' })
    $('.imagehome')
      .css({ display: 'none' })
      .removeClass('washovered')
    $(this)
      .addClass('washovered')
      .children('.imagehome')
      .css({ display: 'block' })
  })
  $('.remca').click(function() {
    $('.imagehome').css({ display: 'none' })
    $('.logichome').removeClass('washovered')
  })
}

// make them properties on a standard object
const methods = {
  componentDidMount,
}

const HomePage = ({ edges }) => {
  const PhotoItem = edges
    .filter(edge => edge.node.frontmatter.category === 'photo')
    .map(edge => <HomePageListItem key={edge.node.id} item={edge.node} />)

  const NanoItem = edges
    .filter(edge => edge.node.frontmatter.category === 'nano')
    .map(edge => <HomePageListItem key={edge.node.id} item={edge.node} />)

  const VoyaItem = edges
    .filter(edge => edge.node.frontmatter.category === 'voya')
    .map(edge => <HomePageListItem key={edge.node.id} item={edge.node} />)

  const LaboItem = edges
    .filter(edge => edge.node.frontmatter.category === 'labo')
    .map(edge => <HomePageListItem key={edge.node.id} item={edge.node} />)

  return (
    <nav className="projlist">
      <ul className={`${HomePageCSS.photonav} photonav`}>
        <HomeCatBar cat="photo" />
        {PhotoItem}
      </ul>
      <ul className={`${HomePageCSS.nanonav} nanonav`}>
        <HomeCatBar cat="nano" />
        {NanoItem}
      </ul>
      <ul className={`${HomePageCSS.labonav} labonav`}>
        <HomeCatBar cat="labo" />
        {LaboItem}
      </ul>
      <ul className={`${HomePageCSS.voyanav} voyanav`}>
        <HomeCatBar cat="ville" />
        {VoyaItem}
      </ul>
      <ul className={`${HomePageCSS.archnav} archnav`}>
        <HomeCatBar cat="archive">
          <Link to="/archive/" alt="Archive Link">
            <div className={`${HomePageCSS.archicon} archicon`} />
          </Link>
        </HomeCatBar>
      </ul>
    </nav>
  )
}

export default lifecycle(methods)(HomePage)
