import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import Content from '../components/content'
import HomeGallery from '../components/home/homegallery'
import Top from '../components/menu/top'

import MobileHomePage from '../components/home/mobile-homepage'
import HomePage from '../components/home/homepage'

import Responsive from 'react-responsive'

import OgImage from '../inc/img/og.jpg'

const Desktop = props => <Responsive {...props} minWidth={992} />
const Mobile = props => <Responsive {...props} maxWidth={991} />

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  return (
    <>
      <Helmet
        title="Rémi Carayon"
        meta={[
          { name: 'description', content: 'Des photos.' },
          { name: 'keywords', content: 'photographie, photographe' },
        ]}
        bodyAttributes={{
          class: 'home',
        }}
        defer={false}
      >
        <meta property="og:title" content="Rémi Carayon" />
        <meta
          property="og:image"
          content={`https://remicarayon.fr${OgImage}`}
        />
      </Helmet>
      <Layout>
        <Top />
        <Content>
          <Desktop>
            <HomePage edges={edges} />
            <HomeGallery />
          </Desktop>
          <Mobile>
            <MobileHomePage edges={edges} />
          </Mobile>
        </Content>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query AllProj {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/projets/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "YYYY")
            path
            title
            specialtag
            category
            hierarchy
            idu
            featured {
              desktop: childImageSharp {
                fluid(maxWidth: 1000, quality: 72) {
                  ...GatsbyImageSharpFluid
                }
              }
              mobile: childImageSharp {
                fluid(
                  maxWidth: 200
                  maxHeight: 140
                  cropFocus: ATTENTION
                  quality: 62
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
export default IndexPage
