import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

import HomeGalleryCSS from './homegallery.module.css'

const randomGenerator = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        allSoloJson {
          edges {
            node {
              id
              item {
                title
                image {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 70) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { allSoloJson } = data
      const { edges } = allSoloJson
      const randomPosition = randomGenerator(0, edges.length - 1)
      const randomizedImage = edges[randomPosition].node
      return (
        <Link className={HomeGalleryCSS.gallerybox} to="/solo/">
          <div className={`${HomeGalleryCSS.bar} gallerybar`} />
          <Img
            className={`${HomeGalleryCSS.imagesolohome} imagesolohome`}
            style={{ position: 'fixed' }}
            imgStyle={{ objectFit: 'contain', objectPosition: 'top center' }}
            fluid={randomizedImage.item.image.childImageSharp.fluid}
          />
        </Link>
      )
    }}
  />
)
