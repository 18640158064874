import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import HomePageListCSSMobile from './mobile-homepagelistitem.module.css'
import Corner from '../corner'

const HomePageListMobile = ({ item, catmob }) => (
  <>
    <li
      className={`${HomePageListCSSMobile.box} ${
        HomePageListCSSMobile[item.frontmatter.category]
      } ${HomePageListCSSMobile[catmob]}`}
    >
      <Link
        className={HomePageListCSSMobile.link}
        activeClassName="active"
        to={item.frontmatter.path}
      >
        <Img
          className={`${HomePageListCSSMobile.imagehome} imagehomemobile`}
          imgStyle={{ objectFit: 'cover' }}
          fluid={item.frontmatter.featured.mobile.fluid}
        />
        <Corner />
        <h2
          className={`${HomePageListCSSMobile.title} ${item.frontmatter.idu}`}
        >
          {item.frontmatter.title}
        </h2>
      </Link>
    </li>
  </>
)

export default HomePageListMobile
