import React from 'react'
import NavCatBarMobileCSS from './mobile-homecatbar.module.css'

const NavCatBarMobile = ({ catmobile, children }) => (
  <div
    className={`${NavCatBarMobileCSS.boxlist} ${catmobile} ${
      NavCatBarMobileCSS[catmobile]
    } boxlist-mob`}
  >
    {children}
  </div>
)

export default NavCatBarMobile
