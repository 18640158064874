import React from 'react'
import { Link } from 'gatsby'

import HomeCatBarCSS from './homecatbar.module.css'

const NavCatBar = ({ cat, children }) => (
  <li className={`${HomeCatBarCSS.box} catbox`}>
    <Link activeClassName="active" to={`/${cat}/`}>
      <div className={`${HomeCatBarCSS.name} catname`}>{cat}</div>
      <div className={`${HomeCatBarCSS.bar} catbar`} />
    </Link>
    {children}
  </li>
)

export default NavCatBar
