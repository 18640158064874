import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import HomePageListCSS from './homepagelistitem.module.css'
import Square from '../../components/square'

const hoverimg = {
  position: 'fixed',
}

const HomePageList = ({ item }) => (
  <>
    <li
      className={[
        item.frontmatter.category,
        item.frontmatter.idu,
        item.frontmatter.hierarchy,
      ].join(' ')}
    >
      <Square />
      <Link
        className="logichome"
        activeClassName="active"
        to={item.frontmatter.path}
      >
        <span className={HomePageListCSS.title}>{item.frontmatter.title}</span>
        <Img
          className={`${HomePageListCSS.imagehome} imagehome`}
          style={hoverimg}
          imgStyle={{
            objectFit: 'contain',
            objectPosition: 'top center',
          }}
          fluid={item.frontmatter.featured.desktop.fluid}
        />
      </Link>
      {item.frontmatter.specialtag !== 0 && (
        <div className={HomePageListCSS.tag}>{item.frontmatter.specialtag}</div>
      )}
    </li>
  </>
)

export default HomePageList
