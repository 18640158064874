import React from 'react'

const bullet = {
  height: '5px',
  width: '5px',
  verticalAlign: 'middle',
  marginRight: '5px',
  marginLeft: '5px',
  marginBottom: '2px',
  display: 'inline-block',
}

const Square = () => <div className="square" style={bullet} />

export default Square
