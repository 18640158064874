import React from 'react'
import HomePageListItemMobile from './mobile-homepagelistitem'
import HomeCatBarMobile from './mobile-homecatbar'

const MobileHomePage = ({ edges }) => {
  const PhotoItemMobile = edges
    .filter(edge => edge.node.frontmatter.hierarchy !== 'child')
    .filter(edge => edge.node.frontmatter.specialtag !== 'new')
    .filter(edge => edge.node.frontmatter.category === 'photo')
    .map(edge => <HomePageListItemMobile key={edge.node.id} item={edge.node} />)

  const NanoItemMobile = edges
    .filter(edge => edge.node.frontmatter.hierarchy !== 'child')
    .filter(edge => edge.node.frontmatter.specialtag !== 'new')
    .filter(edge => edge.node.frontmatter.category === 'nano')
    .map(edge => <HomePageListItemMobile key={edge.node.id} item={edge.node} />)

  const LaboItemMobile = edges
    .filter(edge => edge.node.frontmatter.hierarchy !== 'child')
    .filter(edge => edge.node.frontmatter.specialtag !== 'new')
    .filter(edge => edge.node.frontmatter.category === 'labo')
    .map(edge => <HomePageListItemMobile key={edge.node.id} item={edge.node} />)

  const VoyaItemMobile = edges
    .filter(edge => edge.node.frontmatter.hierarchy !== 'child')
    .filter(edge => edge.node.frontmatter.specialtag !== 'new')
    .filter(edge => edge.node.frontmatter.category === 'voya')
    .map(edge => <HomePageListItemMobile key={edge.node.id} item={edge.node} />)

  const NewItemMobile = edges
    .filter(edge => edge.node.frontmatter.specialtag === 'new')
    .map(edge => (
      <HomePageListItemMobile
        key={edge.node.id}
        item={edge.node}
        catmob="new"
      />
    ))

  return (
    <nav style={{ marginTop: '15px', width: '100%' }}>
      <HomeCatBarMobile catmobile="new">{NewItemMobile}</HomeCatBarMobile>
      <HomeCatBarMobile catmobile="photo">{PhotoItemMobile}</HomeCatBarMobile>
      <HomeCatBarMobile catmobile="labo">{LaboItemMobile}</HomeCatBarMobile>
      <HomeCatBarMobile catmobile="nano">{NanoItemMobile}</HomeCatBarMobile>
      <HomeCatBarMobile catmobile="voya">{VoyaItemMobile}</HomeCatBarMobile>
    </nav>
  )
}

export default MobileHomePage
